import React from "react";
import { About } from "../../constants";

const AboutSection = () => {
  return (
    <div className="container mx-auto py-5">
      <div className="flex flex-wrap">
        {/* First column with images (40%) */}
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <img src={About} className="w-full h-auto" alt="Image 1" />
        </div>

        {/* Second column with details (60%) */}
        <div className="w-full md:w-1/2">
          <h2 className="text-4xl font-bold mb-4" style={{ color: "#0f4f93" }}>
            Some awesome words
            <br />
            <span style={{ color: "#ff7418" }}>about app.</span>
          </h2>
          <p className="text-lg text-dark-blue">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            facilisi. Vestibulum vehicula ultricies massa, sit amet blandit sem
            tincidunt id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            facilisi. Vestibulum vehicula ultricies massa, sit amet blandit sem
            tincidunt id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            facilisi. Vestibulum vehicula ultricies massa, sit amet blandit sem
            tincidunt id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            facilisi. Vestibulum vehicula ultricies massa, sit amet blandit sem
            tincidunt id.
          </p>
          <button className="header-button block px-4 py-2 rounded-full mt-16 ml-auto">
          About Us
        </button>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
