import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import PricingSection from "../Home/Pricing";
import DownloadAppSection from "../Home/Download";


const Pricing: React.FC = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Header/>
      <PricingSection />
      <DownloadAppSection/>
      <Footer/>
    </div>
  );
};

export default Pricing;
