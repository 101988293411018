import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto py-10">
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 mb-4 md:mb-0 bg-white p-6 rounded-lg">
          <h2 className="text-4xl font-bold mb-4">Contact Us</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">Name</label>
              <input type="text" id="name" name="name" className="form-input border w-full" />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email</label>
              <input type="email" id="email" name="email" className="form-input border w-full" />
            </div>
            <div className="mb-4">
              <label htmlFor="message" className="block text-gray-700">Message</label>
              <textarea id="message" name="message" className="form-input border w-full"></textarea>
            </div>
            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-full">Submit</button>
          </form>
        </div>

        {/* Second column with Contact Information */}
        <div className="w-full md:w-1/2">
          <h2 className="text-4xl font-bold mb-4">Contact Information</h2>
          <p className="mb-4">Feel free to reach out to us for any inquiries or assistance. We are here to help!</p>
          <ul className="list-disc ml-6">
            <li className="mb-2">123 Main Street</li>
            <li className="mb-2">City, Country</li>
            <li className="mb-2">Phone: (123) 456-7890</li>
            <li>Email: info@example.com</li>
          </ul>
        </div>

      </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117444.7044527782!2d72.5151085853576!3d23.091713532876714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83c17dc37ee3%3A0xab976f6a3a1f932d!2sNarendra%20Modi%20Stadium!5e0!3m2!1sen!2sin!4v1701688643656!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: '0' }}
          loading="lazy"
          title="Google Maps Location"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
  );
};

export default ContactUs;
