import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { About2 } from "../../constants";

const Section = () => {
  return (
    <div className="container mx-auto py-5">
      <div className="flex flex-wrap">

        <div className="w-full md:w-1/2">
          <p className="text-lg text-dark-blue">
          Lorem Ipsum is simply dummy text of the printing and type
            setting industry lorem Ipsum has been the industrys standard 
            dummy text ever since the when an unknown printer took a 
            galley of type and scrambled it to make a type specimen book.
            It has survived not only five centuries, but also the leap into 
            electronic typesetting, remaining to make a type speci men book. 
            It has survived essentially unchanged.
          </p>
          <p className="text-lg text-dark-blue">
          Lorem Ipsum is simply dummy text of the printing and type
            setting industry lorem Ipsum has been the industrys standard 
            dummy text ever since the when an unknown printer took a 
            galley of type and scrambled it to make a type specimen book.
            It has survived not only five centuries, but also the leap into 
            electronic typesetting, remaining to make a type speci men book. 
            It has survived essentially unchanged.
          </p>
        </div>
        <div className="w-full md:w-1/2 mb-4 md:mb-0">
          <img src={About2} className="w-full h-auto" alt="Image 1" />
        </div>

      </div>
    </div>
  );
};

export default Section;
