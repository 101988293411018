import React from "react";
import Header from "./Header";
import About from "./AboutSection";
import Feature from "./Feat";
import Trust from "./Trust";
import Work from "./Work";
import Rating from "./Rating";
import Pricing from "./Pricing";
import DownloadAppSection from "./Download";
import Footer from "./Footer";

const Home: React.FC = () => {
  return (
        <div className="max-w-screen-xl mx-auto">
          <Header />
          <Trust />
          <Feature />
          <About />
          <Work />
          <Rating />
          <Pricing />
          <DownloadAppSection />
          <Footer />
        </div>
  );
};

export default Home;