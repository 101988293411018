import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Contact from './Cont'


const ContactUs: React.FC = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Header/>
      <Contact/>
      <Footer/>
    </div>
  );
};

export default ContactUs;
