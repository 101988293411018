import React from "react";
import { Logo, Wave2 } from "../../constants";

const Footer = () => {
  return (
    <>
      {/* <img src={Wave2}/> */}
      <div className="bg-gray-800 text-white py-10 relative">
        {/* <img
          src={Wave2}
          alt="Subscription"
          className="w-full absolute h-auto"
          style={{ top: "-100px" }}
        /> */}
        <div className="container mx-auto px-16">
          <div className="flex flex-wrap bg-orange-500 items-center p-4 rounded-lg my-4">
            <div className="w-full md:w-2/3 md:mb-0 pr-4">
              <h4 className="text-lg font-semibold mb-2">
                Subscribe to Our Newsletter
              </h4>
            </div>
            <div className="w-full md:w-1/3 flex">
              <input
                type="email"
                placeholder="Enter your email"
                className="bg-gray-700 text-white px-4 py-2 w-2/3 focus:outline-none"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 ml-2 focus:outline-none"
              >
                Subscribe
              </button>
            </div>
          </div>

          {/* Footer Columns */}
          <div className="flex flex-wrap">
            {/* Column 1 */}
            <div className="w-full md:w-1/4 mb-4">
              <h4 className="text-lg font-semibold mb-4">
                <img src={Logo} />
              </h4>
              <ul>
                <li>
                  <a href="#">support@paperbot.in</a>
                </li>
                <li>
                  <a href="#">+91 99999 99999</a>
                </li>
              </ul>
            </div>

            {/* Column 2 */}
            <div className="w-full md:w-1/4 mb-4">
              <h4 className="text-lg font-semibold mb-4">Useful links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">Contact US</a>
                </li>
                <li>
                  <a href="#">About US</a>
                </li>
                <li>
                  <a href="#">Home</a>
                </li>
              </ul>
            </div>

            {/* Column 3 */}
            <div className="w-full md:w-1/4 mb-4">
              <h4 className="text-lg font-semibold mb-4">Help & Support</h4>
              <ul>
                <li>
                  <a href="#">Support</a>
                </li>
                <li>
                  <a href="#">How it works</a>
                </li>
                <li>
                  <a href="#">Terms & Condition</a>
                </li>
                <li>
                  <a href="#">Privacy Policy</a>
                </li>
              </ul>
            </div>

            {/* Column 4 */}
            <div className="w-full md:w-1/4 mb-4">
              <h4 className="text-lg font-semibold mb-4">Let’s Try Out</h4>
              <ul>
                <li>
                  <a href="#">Play Store</a>
                </li>
                <li>
                  <a href="#">App Store</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
