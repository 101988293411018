import React from "react";
import { Fea1, Fea2, Fea3, Fea4, Phone } from "../../constants";

const Feature = () => {
  return (
    <>
      <div className="text-center py-16">
        <h2 className="text-4xl font-bold mb-4" style={{ color: "#0f4f93" }}>
          Features
          <span style={{ color: "#ff7418" }}> that makes app different!</span>
        </h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. <br />
          Pellentesque enim non commodo blandit enim integer felis.
        </p>
      </div>
      <div className="flex flex-col lg:flex-row bg-white rounded-xl shadow-md my-16">
        {/* Column 1 */}
        <div className="lg:w-1/3 flex flex-col">
          {/* Row 1 in Column 1 */}
          <div className="flex-1 p-8 text-center lg:text-right">
            <img src={Fea1} className="mx-auto lg:mx-0 lg:ml-auto" alt="Feature 1" />
            <h2 className="text-2xl font-bold my-2">Secure data</h2>
            <p className="text-gray-600">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          {/* Row 2 in Column 1 */}
          <div className="flex-1 p-8 text-center lg:text-right">
            <img src={Fea3} className="mx-auto lg:mx-0 lg:ml-auto" alt="Feature 3" />
            <h2 className="text-2xl font-bold my-2">Secure data</h2>
            <p className="text-gray-600">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div>

        {/* Column 2 with Image */}
        <div className="lg:w-1/3 relative">
          {/* Image goes here */}
          <img
            src={Phone}
            alt="Mobile Phone"
            className="absolute inset-0 w-auto h-full object-cover m-auto mt-[-100px]"
          />
        </div>

        {/* Column 3 */}
        <div className="lg:w-1/3 flex flex-col">
          {/* Row 1 in Column 3 */}
          <div className="flex-1 p-8 text-center lg:text-left">
            <img src={Fea2} className="mx-auto lg:mx-0 lg:mr-auto" alt="Feature 2" />
            <h2 className="text-2xl font-bold my-2">Secure data</h2>
            <p className="text-gray-600">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
          {/* Row 2 in Column 3 */}
          <div className="flex-1 p-8 text-center lg:text-left">
            <img src={Fea4} className="mx-auto lg:mx-0 lg:mr-auto" alt="Feature 4" />
            <h2 className="text-2xl font-bold my-2">Secure data</h2>
            <p className="text-gray-600">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
