import React from 'react';

const SignUp = () => {
  return (
    <div className="bg-blue-900 h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-3xl font-extrabold text-center text-blue-900 mb-6">Sign Up</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter your username"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter your email"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter your password"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Confirm Password
            </label>
            <input
              type="cpassword"
              id="password"
              name="password"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter Confirm password"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              className="mt-1 p-2 w-full border rounded-md"
              placeholder="Enter your mobile number"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600 mb-4"
          >
            Sign Up
          </button>
          <p className="text-sm text-center text-gray-600">or sign up with:</p>
          <div className="flex justify-center space-x-2">
            <button
              type="button"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
<span className="material-icons text-3xl mr-2">
mark_email_unread
                  </span>
            </button>
            <button
              type="button"
              className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-900"
            >
              <span className="material-icons text-3xl mr-2">
mark_email_unread
                  </span>
            </button>
            <button
              type="button"
              className="bg-blue-400 text-white px-4 py-2 rounded-md hover:bg-blue-500"
            >
              <span className="material-icons text-3xl mr-2">
mark_email_unread
                  </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
