import React from "react";

const Board = () => {
    return (
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-purple-600">Total Users</h2>
              <p className="text-lg text-gray-800">1,234</p>
            </div>
          </div>
  
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-teal-600">Active Users</h2>
              <p className="text-lg text-gray-800">876</p>
            </div>
          </div>
  
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-orange-500">Products Sold</h2>
              <p className="text-lg text-gray-800">543</p>
            </div>
          </div>

          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-purple-600">Total Users</h2>
              <p className="text-lg text-gray-800">1,234</p>
            </div>
          </div>
  
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-teal-600">Active Users</h2>
              <p className="text-lg text-gray-800">876</p>
            </div>
          </div>
  
          <div className="w-full md:w-1/3 p-4">
            <div className="bg-white rounded-lg p-6 shadow-md">
              <h2 className="text-xl font-bold mb-4 text-orange-500">Products Sold</h2>
              <p className="text-lg text-gray-800">543</p>
            </div>
          </div>
        </div>
      );
}

export default Board