import React from 'react';
import { Download, Fea1 } from '../../constants';

const DownloadAppSection = () => {
  return (
      <div className="container bg-blue-500 rounded-lg mx-auto text-center my-16">
        <div className="flex flex-wrap items-center">
          {/* Details Column */}
          <div className="w-full md:w-1/2 px-4 mb-4 md:mb-0 text-white">
            <h2 className="text-4xl font-bold mb-8">Let’s download free from Apple and Play Store</h2>
            <p className="text-gray-200 mb-8">
              Get our app now and enjoy the amazing features! Available on both the App Store and Play Store.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center">
              {/* App Store Badge */}
              <a href="#" target="_blank" rel="noopener noreferrer" className="mr-4 mb-4 sm:mb-0">
                <img src={Fea1} alt="App Store" className="h-16" />
              </a>

              {/* Play Store Badge */}
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img src={Fea1} alt="Play Store" className="h-16" />
              </a>
            </div>
          </div>

         {/* Image Column */}
<div className="w-full md:w-1/2 px-4 mt-[-120px]">
  <img src={Download} alt="App Image" className="w-full h-auto" />
</div>

        </div>
      </div>
  );
};

export default DownloadAppSection;
