import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Component/Home/Home";
import AboutUs from "./Component/About/About";
import Pricing from "./Component/Pricing/Pricing";
import ContactUs from "./Component/ContactUs/ContactUs";
import Login from "./Component/Login/Login";
import SignUp from "./Component/Login/SignUp";
import OTPVerification from "./Component/Login/OTP";
import ForgotPassword from "./Component/Login/FPassword";
import AdminPanel from "./Component/AdminPanel/Admin";

const App: React.FC = () => {
  return (
    // <div className="max-w-screen-xl mx-auto">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/works" element={<AboutUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<OTPVerification />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/admin" element={<AdminPanel />} />
        </Routes>
      </Router>
    // </div>
  );
};

export default App;
