import React from "react";
import Slider from "react-slick";
import { Client1 } from "../../constants";

const ClientReviewSlider = () => {
  const reviews = [
    {
      id: 1,
      name: "Client A",
      review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      rating: 4,
      image: Client1,
    },
    {
      id: 2,
      name: "Client B",
      review:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      rating: 5,
      image: Client1,
    },
    {
      id: 2,
      name: "Client B",
      review:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      rating: 5,
      image: Client1,
    },
    {
      id: 2,
      name: "Client B",
      review:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      rating: 5,
      image: Client1,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="py-10">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4" style={{ color: "#0f4f93" }}>
          What our
          <span style={{ color: "#ff7418" }}> customer say</span>
        </h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. <br />
          Pellentesque enim non commodo blandit enim integer felis.
        </p>

        <Slider {...settings}>
          {reviews.map((review) => (
            <div key={review.id} className="p-4">
              <div className="rounded-lg p-6 ">
                <h3 className="text-xl font-semibold mb-4">{review.name}</h3>
                <img src={review.image} className="mx-auto" />
                <p className="text-gray-600 mb-4">{`"${review.review}"`}</p>
                <div className="flex justify-center">
                  {Array.from({ length: review.rating }, (_, index) => (
                    <span key={index} className="text-yellow-500">
                      &#9733;
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientReviewSlider;
