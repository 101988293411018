import React from 'react';
import { Price1 } from '../../constants';

const PricingSection = () => {
  const plans = [
    {
      id: 1,
      title: '1 Month',
      price: '₹150',
      features: 'Lorem ipsum dolor sit amet consectetur. Mauris netus semper etiam ipsum nunc.',
      image: Price1,
    },
    {
      id: 2,
      title: 'Quterly',
      price: '₹400',
      features: 'Lorem ipsum dolor sit amet consectetur. Mauris netus semper etiam ipsum nunc.',
      image: Price1,
    },
    {
      id: 3,
      title: 'Yearly',
      price: '₹1000',
      features: 'Lorem ipsum dolor sit amet consectetur. Mauris netus semper etiam ipsum nunc.',
      image: Price1,
    },
  ];

  return (
    <div className="container mx-auto py-5">
         <div className="text-center my-16">
        <h2 className="text-4xl font-bold mb-4" style={{ color: "#0f4f93" }}>
          Best  &
          <span style={{ color: "#ff7418" }}> Simple pricing </span>
        </h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. <br />
          Pellentesque enim non commodo blandit enim integer felis.
        </p>
      </div>
      <div className="flex flex-wrap">
        {plans.map((plan) => (
          <div key={plan.id} className="w-full sm:w-1/3 px-4 mb-4">
            <div className="bg-white p-6 rounded-lg shadow-md text-center">
              <img src={plan.image} alt={`Plan ${plan.id}`} className="m-auto mb-4" />
              <h2 className="text-2xl font-bold mb-4" style={{ color: "#ff7418" }}>{plan.title}</h2>
              <p className="text-3xl font-bold mb-4">{plan.price}</p>
              <p>{plan.features}</p>
              <button className="bg-blue-500 text-white px-4 py-2 my-4 rounded-full">SUBSCRIBE NOW</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingSection;