import React from 'react';
import { Step1, Step2, Step3 } from '../../constants';

const Work = () => {
  return (
    <div className="bg-white py-10">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4" style={{ color: "#0f4f93" }}>
        How it works - easy steps
          </h2>
          <p>
          Lorem ipsum dolor sit amet consectetur. <br/>
Pellentesque enim non commodo blandit enim integer felis.
          </p>

        <div className="flex flex-wrap">
          {/* First Column */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <img src={Step1} alt="Team Member 1" className="mx-auto" />
            <h3 className="text-xl font-semibold my-4">Download App</h3>
            <p className="text-gray-600">Download App either for Windows, Mac or Android</p>
          </div>

          {/* Second Column */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <img src={Step2} alt="Team Member 2" className="mx-auto" />
            <h3 className="text-xl font-semibold my-4">Create Account</h3>
            <p className="text-gray-600">Sign up free for App account. One account for all devices.</p>
          </div>

          {/* Third Column */}
          <div className="w-full md:w-1/2 lg:w-1/3 p-4">
            <img src={Step3} alt="Team Member 3" className="mx-auto" />
            <h3 className="text-xl font-semibold my-4">It’s done, enjoy the App</h3>
            <p className="text-gray-600">Get most amazing app experience, Explore and share the app</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
