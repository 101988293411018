import React from "react";
import Footer from "../Home/Footer";
import Header from "../Home/Header";
import Feature from "../Home/Feat";
import Section from "./Section";


const AboutUs: React.FC = () => {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Header/>
      <Section />
      <Feature />
      <Footer/>
    </div>
  );
};

export default AboutUs;
