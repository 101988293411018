import React, { useState } from "react";
import { Logo, Slider1 } from "../../constants";
import Slider from "react-slick";

const Header: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const images = [Slider1, Slider1, Slider1];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null, 
  };

  return (
    <>
      {/* <header className="p-4 flex items-center justify-between text-white">
      <div>
        <img src={Logo} alt="My Image" className="h-8" />
      </div>

      <nav className="nav-text hidden md:flex text-base font-bold items-center space-x-4">
        <a href="#">Home</a>
        <a href="#">About Us</a>
        <a href="#">How it works</a>
        <a href="#">Pricing</a>
        <a href="#">Contact Us</a>
      </nav>

      <button className="header-button hidden md:block px-4 py-2 rounded-full">
        Get Started
      </button>

      <button
        className="header-button md:hidden px-4 py-2 rounded-full"
        onClick={toggleSidebar}
      >
        ☰
      </button>

      {isSidebarOpen && (
        <div className="fixed top-0 right-0 h-full bg-blue-200 w-64 p-4 transition-transform transform translate-x-0 md:translate-x-full">
          <button className="close-button text-xl mb-4" onClick={toggleSidebar}>
            &times;
          </button>
          <nav className="navbar">
            <a href="#" onClick={toggleSidebar} className="block my-2">
              Home
            </a>
            <a href="#" onClick={toggleSidebar} className="block my-2">
              About Us
            </a>
            <a href="#" onClick={toggleSidebar} className="block my-2">
              How it works
            </a>
            <a href="#" onClick={toggleSidebar} className="block my-2">
              Pricing
            </a>
            <a href="#" onClick={toggleSidebar} className="block my-2">
              Contact Us
            </a>
          </nav>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-full my-4"
            onClick={toggleSidebar}
          >
            Get Started
          </button>
        </div>
      )}
    </header> */}

      <header className={`p-4 ${isNavbarOpen ? "bg-gray-700" : ""}`}>
        <div className="flex items-center justify-between">
          {/* Logo */}
          <img src={Logo} alt="Logo" className="h-8" />

          {/* Navbar for larger screens */}
          <nav className="nav-text hidden md:flex space-x-4 font-bold">
            <a href="/">Home</a>
            <a href="/about-us">About Us</a>
            <a href="/">How it works</a>
            <a href="/pricing">Pricing</a>
            <a href="/contact-us">Contact Us</a>
          </nav>

          {/* Get Started Button for larger screens */}
          <button className="header-button hidden md:block px-4 py-2 rounded-full">
            Get Started
          </button>

          {/* Navbar toggle button for smaller screens */}
          <button
            className={`md:hidden focus:outline-none px-4 py-2 ${
              isNavbarOpen
                ? "text-white"
                : "text-gray-300 header-button rounded-full "
            }`}
            onClick={toggleNavbar}
          >
            {isNavbarOpen ? "X" : "☰"}
          </button>
        </div>

        {/* Collapsible Navbar for smaller screens */}
        {isNavbarOpen && (
          <div className="nav-md-text md:hidden mt-4">
            <a href="/" className="block py-2">
              Home
            </a>
            <a href="/about-us" className="block py-2">
              About Us
            </a>
            <a href="/" className="block py-2">
              How it works
            </a>
            <a href="/pricing" className="block py-2">
              Pricing
            </a>
            <a href="/contact-us" className="block py-2">
              Contact Us
            </a>
          </div>
        )}

        {/* Get Started Button for smaller screens */}
        {isNavbarOpen && (
          <button className="header-button md:hidden block px-4 py-2 rounded-full mt-2">
            Get Started
          </button>
        )}
      </header>

      {/* <Container className="py-5">
    <Row>
      <Col md={6}>
        <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold mb-4 text-blue">paperBOT</h1>
        <h2 className="font-bold text-blue">Easy way to{' '} <span style={{ color: '#ff7418' }}>generate paper</span></h2>
        <p className="text-lg text-dark-blue">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          facilisi. Vestibulum vehicula ultricies massa, sit amet blandit sem
        </p>
      </Col>

      <Col md={6}>
        <Carousel controls={false}>
          {images.map((image, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={image}
                alt={`Slide ${index + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </Row>
  </Container> */}

      <div className="container mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 md:pr-8 mb-8 md:mb-0">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-black mb-4 text-blue">
              paperBOT
            </h1>
            <h2 className="text-lg md:text-2xl lg:text-4xl font-bold text-blue">
              Easy way to{" "}
              <span style={{ color: "#ff7418" }}>generate paper</span>
            </h2>
            <p className="text-lg text-dark-blue">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              facilisi. Vestibulum vehicula ultricies massa, sit amet blandit
              sem
            </p>
            <div className="flex">
              <button className="border-4 border-blue-950 to-transparent text-blue-900 block px-4 py-2 rounded-xl mt-4 mr-4 hover:bg-slate-200 transition duration-300">
                <div className="flex items-center">
                  <span className="material-icons text-3xl mr-2">
                    smart_display
                  </span>
                  <div>
                    <p className="text-sm">Available on the</p>
                    <div className="font-black text-lg">App Store</div>
                  </div>
                </div>
              </button>
              <button className="border-4 border-blue-950 to-transparent text-blue-900 block px-4 py-2 rounded-xl mt-4 hover:bg-slate-200 transition duration-300">
                <div className="flex items-center">
                  <span className="material-icons text-3xl mr-2">
                    smart_display
                  </span>
                  <div>
                    <p className="text-sm">Available on the</p>
                    <div className="font-black text-lg">App Store</div>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <Slider {...sliderSettings}>
              {images.map((image, index) => (
                <div key={index}>
                  <img
                    className="w-full h-auto"
                    src={image}
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
