import React from 'react';
import { Logo, Wave } from '../../constants';

const Trust = () => {
    return (
        <>
            <div className='text-center'>
                <img src={Wave} alt="Logo" width={'100%'} />
                <h2 className="text-4xl font-bold mb-2 mt-8" style={{ color: "#0f4f93" }}>
                    FeaturesTrusted by
                    <span style={{ color: "#ff7418" }}> 150+ </span>
                    companies
                </h2>
                <p>
                    Lorem ipsum dolor sit amet consectetur. <br />
                    Pellentesque enim non commodo blandit enim integer felis.
                </p>
            </div>
            <div className="marquee-container m-auto">
                <div className="marquee-content">
                    {/* Repeat this block for each client logo */}
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    <img src={Logo} alt="Client Logo 1" className="marquee-logo" />
                    {/* Add more logos as needed */}
                </div>
            </div>
        </>
    );
};

export default Trust;
