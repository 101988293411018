import React, { ChangeEvent, KeyboardEvent, useRef } from 'react';

const OTPVerification: React.FC = () => {
  const inputRefs = [useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>(), useRef<HTMLInputElement>()];

  const handleInputChange = (index: number, value: string) => {
    // Move to the next input field if a digit is entered
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    // Move to the previous input field on backspace if the current field is empty
    if (event.key === 'Backspace' && index > 0 && event.currentTarget.value === '') {
      inputRefs[index - 1].current?.focus();
    }
  };

  return (
    <div className="bg-blue-900 h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-3xl font-extrabold text-center text-blue-900 mb-6">OTP Verification</h2>
        <p className="text-sm text-gray-700 mb-6">Enter the 4-digit OTP sent to your mobile number.</p>
        <div className="flex justify-between mb-4">
          {inputRefs.map((ref, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              className="p-2 w-1/4 text-center border rounded-md focus:outline-none mr-2"
              ref={ref}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
            />
          ))}
        </div>
        <form>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600"
          >
            Verify OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;
