import React, { useState } from 'react';

const ForgotPassword = () => {
  const [selectedOption, setSelectedOption] = useState('email');
  const [inputValue, setInputValue] = useState('');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setInputValue(''); // Clear the input field when changing the option
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendOTP = () => {
    // Implement logic to send OTP based on the selected option and inputValue
    console.log(`Sending OTP to ${selectedOption}: ${inputValue}`);
  };

  return (
    <div className="bg-blue-900 h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md w-96">
        <h2 className="text-3xl font-extrabold text-center text-blue-900 mb-6">Forgot Password</h2>
        <div className="flex items-center mb-6">
          <label className="mr-4 cursor-pointer">
            <input
              type="radio"
              value="email"
              checked={selectedOption === 'email'}
              onChange={() => handleOptionChange('email')}
            />
            <span className="ml-2">Email</span>
          </label>
          <label className="cursor-pointer">
            <input
              type="radio"
              value="mobile"
              checked={selectedOption === 'mobile'}
              onChange={() => handleOptionChange('mobile')}
            />
            <span className="ml-2">Mobile Number</span>
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-sm text-gray-700 mb-2">Enter {selectedOption === 'email' ? 'Email' : 'Mobile Number'}</label>
          <input
            type={selectedOption === 'email' ? 'email' : 'tel'}
            value={inputValue}
            onChange={handleInputChange}
            className="p-2 w-full border rounded-md focus:outline-none"
          />
        </div>
        <form>
          <button
            type="button"
            onClick={handleSendOTP}
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full hover:bg-blue-600"
          >
            Send OTP
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
