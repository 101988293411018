import React, { useState } from 'react';
import Dashboard from './Board';
import Board from './Board';

const AdminPanel = () => {
  const [selectedTab, setSelectedTab] = useState('dashboard');

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-200 text-gray-800">
      {/* Left Side - Navigator Menu */}
      <div className="md:w-64 bg-gray-300 p-4">
        <h1 className="text-3xl font-bold mb-4 text-gray-800">Admin Portal</h1>
        <nav>
          <ul>
            <li className={`mb-4 dashboard`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('dashboard')}>
                Dashboard
              </a>
            </li>
            <li className={`mb-4 users`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('board')}>
              My Board
              </a>
            </li>
            <li className={`mb-4 products`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('paper')}>
              Generated Paper
              </a>
            </li>
            <li className={`mb-4 products`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('draft')}>
              Drafted Paper
              </a>
            </li>
            <li className={`mb-4 products`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('refer')}>
              Rafer & Earn
              </a>
            </li>
            <li className={`mb-4 products`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('help')}>
              Help & Support
              </a>
            </li>
            <li className={`mb-4 products`}>
              <a href="#" className="text-lg hover:underline" onClick={() => handleTabClick('logout')}>
              Logout
              </a>
            </li>
            {/* Add more menu items as needed */}
          </ul>
        </nav>
      </div>

      {/* Right Side - Content Area */}
      <div className="flex-1 p-4 md:p-8 bg-gray-300">
        {
          selectedTab == "dashboard" ?
          <Dashboard />
          : selectedTab == "board" ?
          <Board />
          : selectedTab == "paper" ?
          <Board />
          : selectedTab == "draft" ?
          <Board />
          : selectedTab == "refer" ?
          <Board />
          : selectedTab == "board" ?
          <Board />
          : selectedTab == "help" ?
          <Board /> : <> </>
        }
      </div>
    </div>
  );
};

export default AdminPanel;
